import {
  createTheme,
  type PaletteColor,
  type PaletteOptions,
  type ThemeOptions,
} from '@mui/material';

declare module '@mui/material/styles' {
  interface TypeAction {
    rest: string;
  }

  interface PaletteColor {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }
}

const blue: PaletteColor = {
  '50': '#e2f3ff',
  '100': '#b9e1ff',
  '200': '#87cfff',
  '300': '#45bbff',
  '400': '#00abff',
  '500': '#009aff',
  '600': '#008aff',
  '700': '#0076ff', // light
  '800': '#0f63fe', // main
  '900': '#293cdf', // dark
  // colors
  main: '#0f63fe',
  light: '#0076ff',
  dark: '#293cdf',
  contrastText: '#fff',
};

const actionColors: PaletteOptions['action'] = {
  hover: '#e5e5e5',
  active: '#e5e5e5',
};

const themeColors: ThemeOptions = {
  palette: {
    primary: blue,
    action: actionColors,
    divider: '#e0e0e0',
  },
  typography: {
    fontFamily: 'Chakra Petch, sans-serif',
  },
};

const baseTheme: ThemeOptions = {
  ...themeColors,
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '1em',
          borderRadius: 0,
          padding: '0 16px',
          textTransform: 'none',
          minWidth: '10rem',
          minHeight: '3rem',
          '&:focus':
            ownerState.variant === 'contained'
              ? {
                  boxShadow:
                    'inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)),inset 0 0 0 2px var(--cds-background, #f4f4f4)',
                }
              : {
                  outlineOffset: -2,
                  outline: `2px solid var(--cds-interactive-01, ${blue.main})`,
                },
        }),
        outlined: {
          '&:hover': {
            background: actionColors.hover,
          },
        },
        text: {
          '&:hover': {
            background: actionColors.hover,
          },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          minWidth: '10rem',
          padding: '10px 16px',
          '&:focus': {
            outlineOffset: -2,
            outline: `2px solid var(--cds-interactive-01, ${blue.main})`,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
          boxShadow: 'none',
          border: `1px solid ${themeColors.palette?.divider}`,
        },
      },
    },
  },
};

export const theme = createTheme(baseTheme);

export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    background: {
      ...baseTheme.palette?.background,
      default: '#070506',
      paper: '#1a1a1a',
    },
    action: {
      ...baseTheme.palette?.action,
      hover: '#333',
      active: '#444',
    },
    text: {
      ...baseTheme.palette?.text,
      primary: '#fff',
    },
    divider: '#444',
  },
});
