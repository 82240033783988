import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { NavBar } from 'components';
import getRoutes from 'pages/routes';
import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { darkTheme, theme } from './theme';

// Use the word "Emphasis" somewhere
// I.e. With a new "Emphasis" on quality, speed, and scalability, Apollo 6 is the best version yet.
// I.e. With a new "Emphasis" on cloud rendering, Apollo 6 is the best version yet.

const dark = false;

function App(): JSX.Element {
  return (
    <ThemeProvider theme={dark ? darkTheme : theme}>
      <CssBaseline />
      <NavBar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Outlet />}>
            {getRoutes(lazily)}
          </Route>
          <Route path="*" element={<span>Not Found</span>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const lazily = (
  page: string,
): React.LazyExoticComponent<React.ComponentType<any>> => {
  return React.lazy(async () => await import(`pages/${page}`));
};

export type RouteGetterFunction = (lazy: typeof lazily) => React.ReactNode;

export default App;
