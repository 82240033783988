import styled from '@emotion/styled';
import React from 'react';

const StyledImage = styled.img`
  display: flex;
  width: auto;
  height: 1.1em;
`;

export function Logo(): JSX.Element {
  return <StyledImage src="/logo_black.svg" />;
}
