import {
  Button,
  type ButtonProps,
  Divider,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { TfiSearch } from 'react-icons/tfi';
import { VscAccount, VscChevronDown, VscMenu } from 'react-icons/vsc';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Header, IconButton, Logo } from '../';

export default function NavBar(): JSX.Element {
  const { breakpoints } = useTheme();
  const showExtraButtons = useMediaQuery(breakpoints.up('md'));
  return (
    <Header>
      <Stack flexDirection="row" alignItems="stretch">
        <NavButton aria-label="logo" href="/" logo>
          Microart
        </NavButton>
        <Divider orientation="vertical" sx={{ mx: 1 }} />
        <NavButton dropdown title="Services">
          <NavButton item>Services</NavButton>
          <NavButton item>Services</NavButton>
        </NavButton>
        <NavButton>Products</NavButton>
        {showExtraButtons && (
          <>
            <NavButton>Our Work</NavButton>
            <NavButton dropdown title="About">
              <NavButton item>What We Do</NavButton>
              <NavButton item>Privacy Policy</NavButton>
              <NavButton item>Company & Legal</NavButton>
            </NavButton>
            <NavButton>Contact Us</NavButton>
          </>
        )}
      </Stack>
      <Stack flexDirection="row" alignItems="stretch">
        {!showExtraButtons && (
          <IconButton variant="text" align="center">
            <VscMenu />
          </IconButton>
        )}
        <IconButton variant="text" align="center">
          <TfiSearch />
        </IconButton>
        <IconButton variant="text" align="center">
          <VscAccount />
        </IconButton>
      </Stack>
    </Header>
  );
}

type NavButtonProps = ButtonProps & {
  item?: boolean;
  logo?: boolean;
  title?: string;
  dropdown?: boolean;
};

function NavButton(props: NavButtonProps): JSX.Element {
  const {
    href,
    title,
    children,
    item = false,
    logo = false,
    dropdown = false,
    ...rest
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dropdownEl, setDropdownEl] = useState<HTMLElement>();
  const enteredDropdown = useRef(false);

  useEffect(() => {
    if (dropdownEl == null) {
      enteredDropdown.current = false;
    }
  }, [dropdownEl]);

  return (
    <>
      {item ? (
        <MenuItem href={href ?? ''}>{children}</MenuItem>
      ) : (
        <Button
          fullWidth
          variant="text"
          color="inherit"
          startIcon={logo && <Logo />}
          href={dropdown ? undefined : href}
          endIcon={dropdown && <VscChevronDown />}
          onClick={
            dropdown
              ? (x) => {
                  setDropdownEl(x.currentTarget);
                }
              : undefined
          }
          sx={{
            flexShrink: 0,
            width: 'fit-content',
            ...(logo
              ? {
                  fontSize: '1.5em',
                  fontFamily: 'Fonarto',
                  textTransform: 'uppercase',
                }
              : null),
          }}
          {...rest}
        >
          {dropdown ? title : children}
        </Button>
      )}
      {dropdown && (
        <Menu
          onClose={() => {
            setDropdownEl(undefined);
          }}
          open={!(dropdownEl == null)}
          anchorEl={dropdownEl}
        >
          {children}
        </Menu>
      )}
    </>
  );
}
