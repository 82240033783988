import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import React from 'react';

type GridProps = React.ComponentProps<'div'>;
type MuiStackProps = React.ComponentProps<typeof Stack>;

type OverlayProps = GridProps & {
  onto: React.ReactNode;
  stackProps?: MuiStackProps;
};

const StyledOverlay = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  & > * {
    grid-row: 1;
    grid-column: 1;
  }
`;

export default function Overlay(props: OverlayProps): JSX.Element {
  const { onto, stackProps, ...rest } = props;
  return (
    <StyledOverlay {...rest}>
      {onto}
      <Stack {...stackProps}>{props.children}</Stack>
    </StyledOverlay>
  );
}
