import { type RouteGetterFunction } from 'App';
import React from 'react';
import { Route } from 'react-router-dom';

export const routes = [
  {
    path: '/',
    component: 'index',
  },
  {
    path: '/products',
    component: 'products',
  },
];

let RouteComponents: JSX.Element[] = [];

const getRoutes: RouteGetterFunction = (lazy) => {
  if (RouteComponents.length === 0) {
    const components = routes.map((route) => lazy(route.component));

    RouteComponents = routes.map((route, index) => {
      const Component = components[index];
      return (
        <Route
          path={route.path}
          key={route.component}
          element={<Component />}
        />
      );
    });
  }

  return RouteComponents;
};

export default getRoutes;
