import styled from '@emotion/styled';
import {
  Button as MuiButton,
  type ButtonProps as MuiButtonProps,
  type Theme,
} from '@mui/material';
import React from 'react';

import { withoutProps } from '../../utils/styled';

type ButtonProps = MuiButtonProps & {
  endIcon?: React.ReactNode;
  align?: 'start' | 'center';
  variant?: 'contained' | 'outlined' | 'text';
};

const StyledButton = styled(
  MuiButton,
  withoutProps('variant'),
)(
  ({ theme, variant, align }: { theme?: Theme } & ButtonProps) => `
  border: none;
  display: flex;
  /* PLEASE FIX */
  padding: 0 16px;
  font-size: 1rem;
  cursor: pointer;
  min-width: 10rem;
  min-height: 3rem;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;

  ${
    align === 'center'
      ? `
          align-items: center;
          justify-content: center;
        `
      : ``
  }
  
  ${
    variant === 'text'
      ? `
          background-color: transparent;
          color: ${theme?.palette.text.primary};

          &:hover {
            background-color: ${theme?.palette.action.hover};
          }

          &:active {
            background-color: ${theme?.palette.action.active};
          }
        `
      : variant === 'outlined'
      ? `
          background-color: transparent;
          color: ${theme?.palette.primary.main};
          border: 1px solid ${theme?.palette.primary.main};

          &:focus {
            outline: none;
            color: ${theme?.palette.primary.contrastText};
            background-color: ${theme?.palette.primary.main};
            box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)),
              inset 0 0 0 2px var(--cds-background, #f4f4f4);
          }

          &:hover {
            color: ${theme?.palette.primary.contrastText};
            background-color: ${theme?.palette.primary[900]};
          }
        `
      : `
          color: ${theme?.palette.primary.contrastText};
          background-color: ${theme?.palette.primary.main};

          &:hover {
            background-color: ${theme?.palette.primary[900]};
          }
        
          &:active {
            ${/* background-color: ${theme?.palette.primary[700]}; */ ''}
          }

          &:focus {
            outline: none;
            box-shadow: inset 0 0 0 1px var(--cds-button-focus-color, var(--cds-focus, #0f62fe)),
              inset 0 0 0 2px var(--cds-background, #f4f4f4);
          }
        `
  }
  `,
);

export default function Button(props: ButtonProps): JSX.Element {
  const { children, endIcon, ...rest } = props;
  return (
    <StyledButton {...rest}>
      {children}
      {Boolean(endIcon) && (
        <span
          style={{
            fontSize: '1.3rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '0.8rem',
          }}
        >
          {endIcon}
        </span>
      )}
    </StyledButton>
  );
}
