import styled from '@emotion/styled';

import { Button } from '../';

const IconButton = styled(Button)`
  min-width: 0;
  font-size: 1.25rem;
`;

export default IconButton;
