import styled from '@emotion/styled';
import { Stack, type Theme } from '@mui/material';
import React from 'react';

type MuiHeaderProps = React.ComponentProps<typeof Stack>;
type GhostHeaderProps = React.ComponentProps<'div'>;

type HeaderProps = {
  ghostHeaderProps?: GhostHeaderProps;
} & MuiHeaderProps;

const HEADER_HEIGHT = 50;

const StyledGhostHeader = styled.div`
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
`;

const StyledHeader = styled(Stack)(
  ({ theme }: { theme?: Theme }) => `
    top: 0;
    left: 0;
    width: 100vw;
    position: fixed;
    flex-direction: row;
    align-items: stretch;
    box-sizing: border-box;
    height: ${HEADER_HEIGHT}px;
    justify-content: space-between;
    border-bottom: 1px solid ${theme?.palette.divider};
    background-color: ${theme?.palette.background.paper};

    ${theme?.breakpoints.up('lg')} {
      padding: 0 5%;
    }

    ${theme?.breakpoints.up('xl')} {
      padding: 0 12%;
    }

    & button[aria-label='logo'] {
      font-weight: 450;
      font-size: 1.25rem;
      text-align: center;
      letter-spacing: 0.12em;
      text-transform: uppercase;
    }

    & button {
      min-width: 0;
    }
  `,
);

export default function Header(props: HeaderProps): JSX.Element {
  const { ghostHeaderProps, ...rest } = props;
  return (
    <>
      <StyledGhostHeader {...ghostHeaderProps} />
      <StyledHeader {...rest} />
    </>
  );
}
