import type { StyledOptions } from '@emotion/styled';
import { type Theme } from '@mui/material';

export type StyledOptionsShouldForwardProp = Pick<
  StyledOptions,
  'shouldForwardProp'
>;

/**
 * A @emotion/styled helper function to easily generate a simple { shouldForwardProps: !array.includes }
 * object based on a string or array of strings.
 * @param {string | string[]} props A string or array of strings to exclude from forwarding.
 * @returns {StyledOptions} A partial styled options object.
 */
export function withoutProps(
  ...props: string[]
): StyledOptionsShouldForwardProp {
  return {
    shouldForwardProp: (prop: string) => !props.includes(prop),
  };
}

/**
 * This function is used to get the app-wide container paddings
 */
export function getContainerPadding(theme?: Theme): string {
  return `
    ${theme?.breakpoints.down('lg')} {
      padding: 0 16px;
    }

    ${theme?.breakpoints.up('lg')} {
      padding: 0 10%;
    }

    ${theme?.breakpoints.up('xl')} {
      padding: 0 12%;
    }
  `;
}
