import styled from '@emotion/styled';
import { Stack as MuiStack, type Theme } from '@mui/material';
import React from 'react';

import { getContainerPadding } from '../../utils/styled';

type MuiStackProps = React.ComponentProps<typeof MuiStack>;

const StyledContainer = styled(MuiStack)(
  ({ theme }: { theme?: Theme }) => `
    ${getContainerPadding(theme)}
  `,
);

export default function Container(props: MuiStackProps): JSX.Element {
  return <StyledContainer {...props} />;
}
